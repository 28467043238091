jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false,
    }

    slider.flickity(config)
  })

  $('.testimonial-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false,
      adaptiveHeight: true
    }

    slider.flickity(config)
  })

  $('.related-projects-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
      cellAlign: 'left',
      wrapAround: true,
      contain: false,
      watchCSS: true,
      autoPlay: 3000
    }

    slider.flickity(config)
  })

  $('.logo-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
      wrapAround: true,
      contain: false,
      autoPlay: 3000
    }

    slider.flickity(config)
  })

  $('.services-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
      wrapAround: true,
      contain: false,
      watchCSS: true
    }

    slider.flickity(config)
  })
})