// Process Accordion
jQuery(function($) {
  $('.process-item').click(function() {
    const processContent = $(this).children('.process-item--content');
    const processContentHeight = processContent[0].scrollHeight;
    const processContentNone = $('.process-item--content');

    $(this).siblings().removeClass('active');
    $(this).toggleClass('active');

    if($(this).hasClass('active')) {
      processContentNone.css('max-height', `0px`);
      processContent.css('max-height', `${processContentHeight}px`);

    } else {
      processContent.css('max-height', `0px`);
    }
  })

  const firstProcess = $('.process-item:first-of-type');
  const firstProcessLength = firstProcess.length;

  if(firstProcessLength > 0) {
    const firstProcessContent = $(firstProcess).children('.process-item--content');
    const firstProcessContentHeight = firstProcessContent[0].scrollHeight;

    firstProcess.addClass('active');

    if($(firstProcess).hasClass('active')) {
      firstProcessContent.css('max-height', `${firstProcessContentHeight}px`);
    }
  }
});


// Process Slider
jQuery(function($) {
  $('.process-nav-item').click(function() {
    $('.process-nav-item').removeClass('active');
    $(this).addClass('active');
  })

  $('.process-nav-item:first-of-type').addClass('active');

  $('.process-slider').each(function() {
    var slider = $(this)
    var config = {
      prevNextButtons: false,
      pageDots: false,
      wrapAround: false,
      imagesLoaded: true,
      contain: true,
      selectedAttraction: 0.01,
      friction: 0.15,
      cellAlign: 'left',
      draggable: false,
    }

    slider.flickity(config)
  })

  $('.carousel-nav').flickity({
    asNavFor: '.carousel-main',
    prevNextButtons: false,
    pageDots: false,
    wrapAround: false,
    imagesLoaded: true,
    cellAlign: 'left',
    selectedAttraction: 0.01,
    friction: 0.15,
    draggable: true,
    contain: true,
  });

  const navItems = $('.process-nav-item').length;

  if(navItems == 6) {
    $('.process-nav').css('width', '264');

  } else if(navItems == 5) {
    $('.process-nav').css('width', '222');

  } else if(navItems == 4) {
    $('.process-nav').css('width', '180');

  } else if(navItems == 3) {
    $('.process-nav').css('width', '138');

  } else if(navItems == 2) {
    $('.process-nav').css('width', '96');

  } else if(navItems == 1) {
    $('.process-nav').css('width', '54');
  }
});