jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');
  require('./components/header-scroll.js');
  require('./components/accordion.js');
  require('./components/process.js');
  require('./components/parallax-scroll.js');

  loadMorePosts.init();
  loadMoreProjects.init();
});


// Simple Parallax
const simpleParallax = require('simple-parallax-js');
var image = document.getElementsByClassName('thumbnail');
new simpleParallax(image);


// AOS
const mediaQuery = window.matchMedia('(min-width: 550px)');
const eles = document.getElementsByClassName('service-thumbnail-inner');

addEventListener('load', () => {
  if(mediaQuery.matches) {
    for(let i = 0; i < eles.length; i++){
      eles[i].dataset.aos = "fade-up";
    }

    AOS.init();

  } else {
    for(let i = 0; i < eles.length; i++){
      eles[i].dataset.aos = "";
    }
  }
});

addEventListener('resize', () => {
  if(mediaQuery.matches) {
    for(let i = 0; i < eles.length; i++){
      eles[i].dataset.aos = "fade-up";
    }

    AOS.init();

  } else {
    for(let i = 0; i < eles.length; i++){
      eles[i].dataset.aos = "";
    }
  }
});


// Load More Posts
var loadMorePosts = {
	init: function() {
    jQuery('#load-more').click(function(){
      loadMorePosts.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_posts',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more').replaceWith('<a class="button button-blue-border no-posts" href="#load-more-anchor">Back To Top</a>');
				}

				return;
			}
		});
	}
}


// Load More Projects
var loadMoreProjects = {
	init: function() {
    jQuery('#project-load-more').click(function(){
      loadMoreProjects.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#project-load-container');
		var postCount = jQuery('#project-load-container .project-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_projects',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#project-load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#project-load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#project-load-container .project-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#project-load-more').replaceWith('<a class="button button-blue-border no-posts" href="#projects-load-more-anchor">Back to top</a>');
				}

				return;
			}
		});
	}
}