// Mobile Menu
jQuery(function($) {
  $('#mobile-menu').click(function(){
    $('header').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');

    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });

  var itemHasChildren = $('.menu-item-has-children');
  itemHasChildren.append('<span class="expand-child-items"></span>')

  $('.expand-child-items').click(function () {
    $(this).toggleClass('active');
    $(this).parent('.menu-item-has-children').find('ul').first().toggleClass('active');
  });
});


// Form Mobile Menu
jQuery(function($) {
  $('.popup-form-button').click(function(){
    $('.popup-form-container').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
    } else {
      $('.mobile-overlay').toggleClass('mobile-overlay-active');
    }

    if($('.popup-form-container').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');

    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });

  $('#form-mobile-menu').click(function(){
    $('.popup-form-container').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
    } else {
      $('.mobile-overlay').toggleClass('mobile-overlay-active');
    }

    if($('.popup-form-container').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');

    } else if($('header').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');

    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });
});